import React, { useState } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import { Grid } from '@material-ui/core';
import { validatePassword } from 'shared/utils/misc';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import pxToRem from 'assets/theme/functions/pxToRem';
import { Auth } from 'aws-amplify';
import { ConfirmModal } from 'shared/modals/confirmModal';

const DashboardPersonalSettingsPassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPasswordChangeSuccess, setShowPasswordChangeSuccess] =
    useState(false);
  const [showPasswordChangeFail, setShowPasswordChangeFail] = useState(false);
  const [passwordChangeErrorCode, setPasswordChangeErrorCode] = useState('');
  const [warnText, setWarnText] = useState('');
  const [showWarnText, setShowWarnText] = useState(false);

  // toggle show/hide input
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetInputs = () => {
    setPasswords({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setShowWarnText(false);
    setWarnText('');
  };

  const handlePasswordInput = (key) => async (event) => {
    setShowWarnText(false);
    setPasswords({ ...passwords, [key]: event.target.value });
    if (
      key === 'confirmNewPassword' ||
      (key === 'newPassword' && passwords.confirmNewPassword !== '')
    ) {
      setShowWarnText(passwords.newPassword !== event.target.value);
      setWarnText(
        intl.get('personal_settings_password_warn_text_inconsistent_input')
      );
    }
  };

  const handleClickUpdateButton = () => {
    // check empty input;
    if (
      passwords.currentPassword === '' ||
      passwords.newPassword === '' ||
      passwords.confirmNewPassword === ''
    ) {
      setShowWarnText(true);
      setWarnText(intl.get('personal_settings_password_warn_text_empty_input'));
      return;
    } else if (passwords.newPassword !== passwords.confirmNewPassword) {
      // check inconsistent inputs;
      setShowWarnText(true);
      setWarnText(
        intl.get('personal_settings_password_warn_text_inconsistent_input')
      );
      return;
    } else if (!validatePassword(passwords.newPassword)) {
      setShowWarnText(true);
      setWarnText(
        intl.get('personal_settings_password_requirements_message_2')
      );
      return;
    }

    setShowConfirmModal(true);
  };

  const handleConfirmUpdateButton = () => {
    setShowConfirmModal(false);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          passwords.currentPassword,
          passwords.newPassword
        );
      })
      .then((data) => {
        setShowPasswordChangeSuccess(true);
        resetInputs();
      })
      .catch((err) => {
        console.log(`err: ${JSON.stringify(err)}`);
        setPasswordChangeErrorCode(err.code);
        setShowPasswordChangeFail(true);
      });
  };

  const passwordChangeFailWindowContents = () => {
    if (passwordChangeErrorCode === 'NotAuthorizedException') {
      return (
        <Grid xs={12} item>
          <MDTypography>
            {intl.get('personal_settings_password_error_not_authorized_title')}
          </MDTypography>
          <MDTypography style={{ marginBottom: '10px' }}>
            {intl.get(
              'personal_settings_password_error_not_authorized_message_1'
            )}
          </MDTypography>
        </Grid>
      );
    } else if (passwordChangeErrorCode === 'InvalidPasswordException') {
      return (
        <Grid xs={12} item>
          <MDTypography>
            {intl.get(
              'personal_settings_password_error_invalid_password_message_1'
            )}
          </MDTypography>
          <MDTypography>
            {'\u2022'}{' '}
            {intl.get(
              'personal_settings_password_error_invalid_password_message_2'
            )}
          </MDTypography>
          <MDTypography>
            {'\u2022'}{' '}
            {intl.get(
              'personal_settings_password_error_invalid_password_message_3'
            )}
          </MDTypography>
          <MDTypography>
            {'\u2022'}{' '}
            {intl.get(
              'personal_settings_password_error_invalid_password_message_4'
            )}
          </MDTypography>
          <MDTypography style={{ marginBottom: '10px' }}>
            {'\u2022'}{' '}
            {intl.get(
              'personal_settings_password_error_invalid_password_message_5'
            )}
          </MDTypography>
        </Grid>
      );
    } else if (passwordChangeErrorCode === 'NetworkError') {
      return (
        <Grid xs={12} item>
          <MDTypography style={{ marginBottom: '10px' }}>
            {intl.get(
              'personal_settings_password_error_network_error_message_1'
            )}
          </MDTypography>
        </Grid>
      );
    } else if (passwordChangeErrorCode === 'LimitExceededException') {
      return (
        <Grid xs={12} item>
          <MDTypography style={{ marginBottom: '10px' }}>
            {intl.get(
              'personal_settings_password_error_limit_exceeded_message_1'
            )}
          </MDTypography>
          <MDTypography style={{ marginBottom: '10px' }}>
            {intl.get(
              'personal_settings_password_error_limit_exceeded_message_2'
            )}
          </MDTypography>
        </Grid>
      );
    } else {
      return (
        <Grid xs={12} item>
          <MDTypography style={{ marginBottom: '10px' }}>
            Unkown Error
          </MDTypography>
        </Grid>
      );
    }
  };

  return (
    <MDBox>
      <Grid item xs={12}>
        <MDBox mb={1} mt={8}>
          <MDTypography>
            {intl.get('personal_settings_password_current_password')}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            required
            fullWidth
            type={showCurrentPassword ? 'text' : 'password'}
            autoComplete="current-password"
            onChange={handlePasswordInput('currentPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                    color="info">
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDBox mb={1}>
          <MDTypography>
            {intl.get('personal_settings_password_new_password')}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            required
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            autoComplete="new-password"
            onChange={handlePasswordInput('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    color="info">
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDBox mb={1}>
          <MDTypography>
            {intl.get('personal_settings_password_confirm_new_password')}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            required
            fullWidth
            type={showConfirmNewPassword ? 'text' : 'password'}
            autoComplete="confirm-new-password"
            onChange={handlePasswordInput('confirmNewPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    color="info">
                    {showConfirmNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </Grid>

      {showWarnText && (
        <Grid item xs={12}>
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              {warnText}
            </MDTypography>
          </MDBox>
        </Grid>
      )}

      <Grid item xs={12}>
        <MDBox mb={1}>
          <MDTypography variant="body1">
            {intl.get('personal_settings_password_requirements_message_1')}
          </MDTypography>
          <MDTypography variant="body1">
            {intl.get('personal_settings_password_requirements_message_2')}
          </MDTypography>
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDButton
          variant="gradient"
          color="info"
          fullWidth
          onClick={handleClickUpdateButton}
          style={{
            color: 'white',
            fontSize: pxToRem(18),
            marginTop: '25px',
            marginBottom: '15px',
            width: '50%',
            marginLeft: '25%',
          }}>
          {intl.get('personal_settings_button_update')}
        </MDButton>
      </Grid>

      <ConfirmModal
        isOpened={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmUpdateButton}
        title={intl.get('personal_settings_password_confirm_modal_title')}
        confirmButtonText={intl.get(
          'personal_settings_password_confirm_modal_button_yes'
        )}
        cancelButtonText={intl.get(
          'personal_settings_password_confirm_modal_button_no'
        )}
      />

      <ConfirmModal
        isOpened={showPasswordChangeSuccess}
        onConfirm={() => setShowPasswordChangeSuccess(false)}
        bodyText={intl.get('personal_settings_password_success_message_1')}
        confirmButtonText={intl.get(
          'organization_settings_client_settings_modal_text_confirm_title'
        )}
      />

      <ConfirmModal
        isOpened={showPasswordChangeFail}
        onConfirm={() => setShowPasswordChangeFail(false)}
        title={intl.get('personal_settings_password_fail_message_1')}
        confirmButtonText={intl.get(
          'organization_settings_client_settings_modal_text_confirm_title'
        )}>
        <MDBox>
          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end">
            {passwordChangeFailWindowContents()}
          </Grid>
        </MDBox>
      </ConfirmModal>
    </MDBox>
  );
};

export default withRouter(DashboardPersonalSettingsPassword);
