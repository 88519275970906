import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';

import InvoiceHeader from './InvoiceHeader';
import InvoiceTitle from './InvoiceTitle';
import InvoiceNoAndDate from './InvoiceNoAndDate';
import InvoiceFromAndTo from './InvoiceFromAndTo';
import InvoiceTotalAmountAndDue from './InvoiceTotalAmountAndDue';
import InvoiceDetails from './InvoiceDetails';
import InvoiceAmountList from './InvoiceAmountList';
import InvoicePaymentMethod from './InvoicePaymentMethod';
import InvoiceRemarks from './InvoiceRemarks';
import InvoiceFooter from './InvoiceFooter';
import {
  generateInvoiceFrom,
  generateInvoiceTo,
  generateInvoiceTotalAmount,
  generateInvoiceAmountListItems,
  generateInvoicePaymentMethod,
  generateInvoiceRemarks,
  generateInvoiceDetails,
  generateInvoiceNo,
  convertDate,
} from './utils';
import { styles } from './styles';

const propTypes = {
  invoiceSrcOrgName: PropTypes.string,
  invoiceSrcOrgId: PropTypes.number,
  invoiceIssuesData: PropTypes.array,
  invoiceIssueDate: PropTypes.object,
  invoiceDueDate: PropTypes.object,
  invoiceRemarksContent: PropTypes.string,
  invoiceDstOrg: PropTypes.object,
  invoiceVersion: PropTypes.number,
};

const DashboardBillingInvoice = ({
  invoiceSrcOrgName,
  invoiceSrcOrgId,
  invoiceIssuesData,
  invoiceIssueDate,
  invoiceDueDate,
  invoiceRemarksContent,
  invoiceDstOrg,
  invoiceVersion,
}) => {
  const invoiceDetails = generateInvoiceDetails(invoiceIssuesData);
  const invoiceNo = generateInvoiceNo(
    invoiceSrcOrgId,
    invoiceDstOrg.id,
    invoiceIssueDate,
    invoiceVersion
  );
  const invoiceDate = convertDate(invoiceIssueDate);
  const invoiceTo = generateInvoiceTo(invoiceSrcOrgName);
  const invoiceFrom = generateInvoiceFrom(invoiceDstOrg);
  const invoiceTotalAmount = generateInvoiceTotalAmount(invoiceIssuesData);
  const invoiceDue = convertDate(invoiceDueDate);
  const invoiceAmountListItems =
    generateInvoiceAmountListItems(invoiceIssuesData);
  const invoicePaymentMethod = generateInvoicePaymentMethod(invoiceDstOrg);
  const invoiceRemarks = generateInvoiceRemarks(invoiceRemarksContent);

  return (
    <PDFViewer width="800" height="800">
      <Document title="invoice.pdf">
        <Page size="A4" style={styles.firstPage} wrap={true}>
          <InvoiceHeader />
          <InvoiceNoAndDate invoiceNo={invoiceNo} invoiceDate={invoiceDate} />
          <InvoiceTitle />
          <InvoiceFromAndTo invoiceTo={invoiceTo} invoiceFrom={invoiceFrom} />
          <InvoiceTotalAmountAndDue
            invoiceTotalAmount={invoiceTotalAmount}
            invoiceDue={invoiceDue}
          />
          <InvoiceAmountList invoiceAmountListItems={invoiceAmountListItems} />
          <InvoicePaymentMethod invoicePaymentMethod={invoicePaymentMethod} />
          <InvoiceRemarks invoiceRemarks={invoiceRemarks} />
          <InvoiceFooter />
        </Page>
        {invoiceDetails.map((pageData, index) => {
          return (
            <Page size="A4" style={styles.firstPage} wrap={true} key={index}>
              <InvoiceHeader />
              <Text style={styles.invoiceDetailsTitle}>
                {intl.get('billing_invoice_text_details')}
              </Text>
              <InvoiceDetails pageData={pageData} />
              <InvoiceFooter />
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
};

DashboardBillingInvoice.propTypes = propTypes;

export default withRouter(DashboardBillingInvoice);
