import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';

const propTypes = {
  modalClose: PropTypes.func.isRequired,
  alertModalInfo: PropTypes.string,
};

const AlertWindow = ({ modalClose, alertModalInfo }) => {
  const [isWorking] = useState(false);

  const generateAlertMessage = (alertModalInfo) => {
    if (alertModalInfo === 'invalidDestination') {
      return intl.get('kanban_alert_popwindow_text_invalid_destination');
    } else if (alertModalInfo === 'moveForwardWithWarning') {
      return intl.get('kanban_alert_popwindow_text_move_with_warning');
    }
  };

  const alertMessage = generateAlertMessage(alertModalInfo);

  return (
    <Fragment>
      <Message>{alertMessage}</Message>
      <Actions>
        <StyledButton
          variant={'primary'}
          isWorking={isWorking}
          onClick={modalClose}>
          {intl.get('kanban_alert_popwindow_button_yes')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};

AlertWindow.propTypes = propTypes;

export default AlertWindow;
