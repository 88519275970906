const westernOrderLanguage = ['en-US'];

const easternOrderLanguage = ['ja-JP', 'zh-CN'];

const isWesternOrder = (language) => {
  return westernOrderLanguage.includes(language);
};

const isEasternOrder = (language) => {
  return easternOrderLanguage.includes(language);
};

export { isWesternOrder, isEasternOrder };
