import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TextEditedContent, Button } from 'components';
import { TopActions, Content, Title, Actions, Left } from './styles';

const propTypes = {
  dataType: PropTypes.string,
  dataId: PropTypes.string,
  data: PropTypes.object,
  deleteData: PropTypes.func,
  close: PropTypes.func,
};

const IWGDashboardDelete = ({ dataType, dataId, data, deleteData, close }) => {
  const handleDelete = () => {
    deleteData(dataId);
    close();
  };

  return (
    <Fragment>
      <TopActions>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            fontWeight: 'bold',
            marginTop: '5px',
            marginLeft: '14px',
            textAlign: 'right',
            alignSelf: 'stretch',
          }}>
          {`Do you want to DELETE ${dataType}: ${dataId}`}
        </Typography>
      </TopActions>
      <Content>
        <Left>
          <Title>Data Details</Title>
          <TextEditedContent content={JSON.stringify(data, undefined, 4)} />
          <Actions>
            <Button variant="primary" onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="empty" onClick={close}>
              Cancel
            </Button>
          </Actions>
        </Left>
      </Content>
    </Fragment>
  );
};

IWGDashboardDelete.propTypes = propTypes;

export default withRouter(IWGDashboardDelete);
