import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';
import {
  issueStatusColors,
  issueStatusBackgroundColors,
  mixin,
} from 'shared/utils/styles';
import { IssueStatus } from 'shared/constants/issues';

export const Status = styled.div`
  text-transform: uppercase;
  transition: all 0.1s;
  text-align: center;
  margin: auto;
  ${(props) => {
    if (
      props.color === IssueStatus.NEWTASK ||
      props.color === IssueStatus.CHECKREADY
    )
      return css`
        width: 65px;
      `;

    return css`
      width: 80px;
    `;
  }}
  ${(props) =>
    mixin.tagIssueListStatus(
      issueStatusBackgroundColors[props.color],
      issueStatusColors[props.color]
    )}
  ${(props) =>
    props.isValue &&
    css`
      padding: 0 12px;
      height: 40px;
      &:hover {
        transform: scale(1.05);
      }
    `}
`;

const useStyles = makeStyles((theme) => ({}));

export default useStyles;
