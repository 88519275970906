import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import { IssueStatus, IssueState } from 'shared/constants/issues';
import api from 'shared/utils/api';

// TODO Add propTypes
const propTypes = {
  project: PropTypes.object,
  issueId: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  confirmModalUpdateData: PropTypes.object.isRequired,
  confirmModalInfo: PropTypes.object.isRequired,
};

const DashboardKanbanConfirmPopUpWindow = ({
  project,
  modalClose,
  updateLocalProjectIssues,
  confirmModalUpdateData,
  confirmModalInfo,
}) => {
  const [isWorking] = useState(false);

  const updateIssue = (
    issueId,
    destination,
    project,
    listPosition,
    issueState,
    userIds,
    users
  ) => {
    const updatedFields = {
      status: destination.droppableId,
      listPosition: listPosition,
      state: issueState,
    };
    if (userIds) {
      updatedFields.userIds = userIds;
    }
    if (users) {
      updatedFields.users = users;
    }

    api.optimisticUpdate(`/api/issues/${issueId}`, {
      updatedFields: updatedFields,
      currentFields: project.issues.find(({ id }) => id === issueId),
      setLocalData: (fields) => updateLocalProjectIssues(issueId, fields),
    });
  };

  const handleYesButtonClick = () => {
    const { issueId, destination, listPosition, issueState, userIds, users } =
      confirmModalUpdateData;
    updateIssue(
      issueId,
      destination,
      project,
      listPosition,
      issueState,
      userIds,
      users
    );
    modalClose();
  };

  const generateConfirmMessage = (confirmModalInfo) => {
    if (confirmModalInfo.issueState === IssueState.NORMAL) {
      if (confirmModalInfo.destination === IssueStatus.CHECKREADY) {
        return intl.get('kanban_confirm_popup_window_text_check_ready');
      } else if (confirmModalInfo.destination === IssueStatus.DOCTORASSIGNED) {
        return intl.get('kanban_confirm_popup_window_text_doctor_assigned');
      } else if (confirmModalInfo.destination === IssueStatus.REPORTREADY) {
        return intl.get('kanban_confirm_popup_window_text_report_ready');
      } else if (confirmModalInfo.destination === IssueStatus.SENDBACK) {
        return intl.get('kanban_confirm_popup_window_text_send_back');
      }
    } else if (confirmModalInfo.issueState === IssueState.WARNING) {
      return intl.get('kanban_confirm_popup_window_change_status_with_warning');
    }
    return 'hahahah';
  };
  const confirmMessage = generateConfirmMessage(confirmModalInfo);

  return (
    <Fragment>
      <Message>{confirmMessage}</Message>
      <Actions>
        <StyledButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleYesButtonClick}>
          {intl.get('kanban_confirm_popup_window_button_yes')}
        </StyledButton>
        <StyledButton hollow onClick={modalClose}>
          {intl.get('kanban_confirm_popup_window_button_no')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};
DashboardKanbanConfirmPopUpWindow.propTypes = propTypes;

export default DashboardKanbanConfirmPopUpWindow;
