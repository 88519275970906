import localData from './localData';
import { isWesternOrder, isEasternOrder } from './language';

const getUserFullName = (...args) => {
  let firstName = localData.get('userFirstName');
  let lastName = localData.get('userLastName');
  let preferredLanguage = localData.get('userPreferredLanguage')
    ? localData.get('userPreferredLanguage')
    : 'en-US';
  if (args.length >= 2) {
    firstName = args[0];
    lastName = args[1];
  }
  if (args.length >= 3) {
    preferredLanguage = args[2];
  }
  return generateUserFullName(firstName, lastName, preferredLanguage);
};

const generateUserFullName = (firstName, lastName, preferredLanguage) => {
  if (isWesternOrder(preferredLanguage)) {
    return firstName + ' ' + lastName;
  }
  if (isEasternOrder(preferredLanguage)) {
    return lastName + ' ' + firstName;
  }
  return firstName + ' ' + lastName;
};

const getSavedName = () => {
  const firstName = localData.get('userFirstName');
  const lastName = localData.get('userLastName');
  return { firstName, lastName };
};

const setSavedName = (firstName, lastName) => {
  localData.set('userFirstName', firstName);
  localData.set('userLastName', lastName);
};

export { getUserFullName, generateUserFullName, getSavedName, setSavedName };
