import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { IssuePriorityIcon2 } from 'components';
import { IssueState } from 'shared/constants/issues';
import { Grid } from '@material-ui/core';
import { Tags } from 'shared/constants/tags';
import {
  IssueLink,
  Issue,
  KanbanItemTitle,
  Bottom,
  Assignees,
  AssigneeAvatar,
} from './Styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const propTypes = {
  projectUsers: PropTypes.array.isRequired,
  issue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const ProjectBoardListIssue = ({ projectUsers, issue, index }) => {
  const match = useRouteMatch();
  const assignees = issue.userIds.map((userId) =>
    projectUsers.find((user) => user.id === userId)
  );

  const tags = () => {
    const tagStrings = issue.tags.map((t) => Tags[t]);
    return tagStrings.join(`, `);
  };

  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided, snapshot) => (
        <IssueLink
          to={{
            pathname: `${match.url}/issues/${issue.id}`,
            state: { trigger: 'byClick' },
          }}
          ref={provided.innerRef}
          data-testid="list-issue"
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Issue
            isBeingDragged={snapshot.isDragging && !snapshot.isDropAnimating}>
            <KanbanItemTitle>
              <Grid container>
                <Grid columns={{ xs: 12 }} container alignItems="flex-start">
                  {issue.state === IssueState.WARNING && (
                    <WarningAmberIcon color="warning" />
                  )}
                  <p>{issue.title}</p>
                </Grid>

                <Grid xs={12} item container justifyContent="flex-start">
                  <p>{issue.srcOrgName}</p>
                </Grid>

                <Grid xs={12} item container justifyContent="flex-start">
                  <p>{issue.patientName}</p>
                </Grid>
                {issue.id > 30 && (
                  <Grid xs={12} item container justifyContent="flex-start">
                    <p>{tags()}</p>
                  </Grid>
                )}
              </Grid>
            </KanbanItemTitle>
            <Bottom>
              <div>
                <IssuePriorityIcon2 priority={issue.priority} />
              </div>
              <Assignees>
                {assignees.map((user) => (
                  <AssigneeAvatar
                    key={user.id}
                    size={24}
                    name={user.firstName}
                  />
                ))}
              </Assignees>
            </Bottom>
          </Issue>
        </IssueLink>
      )}
    </Draggable>
  );
};

ProjectBoardListIssue.propTypes = propTypes;

export default ProjectBoardListIssue;
