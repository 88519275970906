import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Button, Tooltip, Modal } from '@material-ui/core';
import StarIcon from '@mui/icons-material/Star';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import Invoice from '../../shared/Invoice';

import CustomTitle from './CustomTitle';
import CustomToolbarSelect from './CustomToolbarSelect';

import useStyles from './styles';
import { formatTime } from 'shared/utils/formatTime';
import { getPrice } from '../../shared/utils';

// import { sortCompareDate } from 'shared/utils/misc';
import { getIssuePriority } from 'shared/constants/issues';

const propTypes = {
  history: PropTypes.object,
  issuesData: PropTypes.array,
  pricesDict: PropTypes.object,
  srcOrgNamesDict: PropTypes.object,
  selectedSrcOrgId: PropTypes.string,
  setTableType: PropTypes.func,
};

const DashboardBillingForReceivedCasesDetailedTable = ({
  history,
  issuesData,
  pricesDict,
  srcOrgNamesDict,
  selectedSrcOrgId,
  setTableType,
}) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const [invoiceIssuesData, setInvoiceIssuesData] = useState([]);

  const goToViewer = (issueId) => {
    history.push({
      pathname: `/received/history/issues/${issueId}`,
    });
  };

  useEffect(() => {
    const getDetailedTableData = (
      _issuesData,
      _pricesDict,
      _srcOrgNamesDict,
      _selectedSrcOrgId
    ) => {
      const issues = _issuesData.filter(
        (item) => item.srcOrgId.toString() === _selectedSrcOrgId
      );

      const tableData = issues.map((issue) => {
        const title = issue.title;
        const modality = issue.modality;
        const bodypart = issue.bodypart;
        const emergency = getIssuePriority(issue.priority);
        const orderTime = issue.createdAt;
        const completeTime = issue.updatedAt;
        const price = getPrice(issue, pricesDict);
        const issueId = issue.id;
        const label = {
          star: issue.starredByDst,
          question: issue.question,
        };
        const difference = issue.difference;
        const discount = issue.discount;
        const waive = issue.waive;
        return [
          title,
          modality,
          bodypart,
          emergency,
          orderTime,
          completeTime,
          {
            originalPrice: price,
            currentPrice: waive ? 0 : price * discount + difference,
            waive: waive,
            discount: discount,
            difference: difference,
          },
          issueId,
          label,
        ];
      });

      setData(tableData);
    };

    getDetailedTableData(
      issuesData,
      pricesDict,
      srcOrgNamesDict,
      selectedSrcOrgId
    );
  }, [issuesData, pricesDict, srcOrgNamesDict, selectedSrcOrgId]);

  const columns = [
    {
      name: 'case_title',
      label: intl.get('billing_for_received_cases_label_column_case_title'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const words1 = obj1.data.split('-');
            const words2 = obj2.data.split('-');
            if (words1[0] > words2[0]) {
              return order === 'desc' ? -1 : 1;
            } else if (words1[0] < words2[0]) {
              return order === 'desc' ? 1 : -1;
            } else {
              if (words1[1] > words2[1]) {
                return order === 'desc' ? -1 : 1;
              } else if (words1[1] < words2[1]) {
                return order === 'desc' ? 1 : -1;
              } else {
                return (
                  (parseInt(words1[2], 10) - parseInt(words2[2], 10)) *
                  (order === 'desc' ? -1 : 1)
                );
              }
            }
          };
        },
      },
    },
    {
      name: 'modality',
      label: intl.get('billing_for_received_cases_label_column_modality'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'bodypart',
      label: intl.get('billing_for_received_cases_label_column_bodypart'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'emergency',
      label: intl.get('billing_for_received_cases_label_column_emergency'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'order_time',
      label: intl.get('billing_for_received_cases_label_column_order_time'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatTime(value);
        },
      },
    },
    {
      name: 'complete_time',
      label: intl.get('billing_for_received_cases_label_column_complete_time'),
      options: {
        filter: true,
        sort: true,
        // sortCompare: sortCompareDate,
        customBodyRender: (value, tableMeta, updateValue) => {
          return formatTime(value);
          // const {priority, deadline} = value;
          // return priority === '2' ? formatDeadline(deadline) : deadline;
        },
      },
    },
    {
      name: 'price',
      label: intl.get('billing_for_received_cases_label_column_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const originalPrice = value.originalPrice;
          const currentPrice = value.currentPrice;

          if (currentPrice === null || currentPrice === originalPrice) {
            return (
              <div>
                <p>￥{originalPrice}</p>
              </div>
            );
          }
          return (
            <div>
              <p>
                ￥{currentPrice} <del>￥{originalPrice}</del>
              </p>
            </div>
          );
        },
      },
    },
    {
      name: 'view',
      label: intl.get('billing_for_received_cases_label_column_details'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => goToViewer(value)}>
                {intl.get('billing_for_received_cases_button_go_to_viewer')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'flag',
      label: intl.get('billing_for_received_cases_label_column_flag'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value.star && (
                <Tooltip
                  className={classes.tooltipMark}
                  title={intl.get(
                    'billing_for_received_cases_detailed_tooltip_star_mark'
                  )}>
                  <StarIcon fontSize="large" color="warning" />
                </Tooltip>
              )}
              {value.question && (
                <Tooltip
                  className={classes.tooltipMark}
                  title={intl.get(
                    'billing_for_received_cases_detailed_tooltip_question_mark'
                  )}>
                  <HelpIcon fontSize="large" color="error" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPage: 25,
    page: 1,
    // onRowClick: (rowData, rowState) => {
    //   let _id = rowData[9];
    //   history.push({
    //     pathname: `/viewer/${_id}`,
    //   });
    // },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          data={data}
          setData={setData}
          setInvoiceIssuesData={setInvoiceIssuesData}
          setModalInvoiceOpen={setModalInvoiceOpen}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
        // columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <MUIDataTable
        title={
          <CustomTitle
            srcOrgName={srcOrgNamesDict[selectedSrcOrgId]}
            setTableType={setTableType}
          />
        }
        data={data}
        columns={columns}
        options={options}
      />

      <br />
      <br />

      {/* Irregular Modal */}
      <Modal
        open={modalInvoiceOpen}
        onClose={() => {
          setModalInvoiceOpen(false);
        }}
        className={classes.modalInvoice}>
        <div className={classes.modalInvoicePaper}>
          <Invoice invoiceIssuesData={invoiceIssuesData} />
        </div>
      </Modal>
    </Fragment>
  );
};

DashboardBillingForReceivedCasesDetailedTable.propTypes = propTypes;

export default withRouter(DashboardBillingForReceivedCasesDetailedTable);
