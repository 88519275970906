import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { PageLoader, PageError } from 'components';

import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';

import iconNewInCharge from 'assets/images/icons/icon-homepage-new-in-charge.svg';
import iconNewReceived from 'assets/images/icons/icon-homepage-new-received.svg';
import iconNewAssigned from 'assets/images/icons/icon-homepage-new-assigned.svg';
import iconNewSendback from 'assets/images/icons/icon-homepage-new-sendback.svg';

const DashboardHome = () => {
  const userType = localData.get('userType');
  const currentOrganizationId = localData.get('currentOrganizationId');

  const userFullName = getUserFullName();

  const [{ data, error }] = useApi.get('/api/organizations/todos', {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { numberNewInCharge, numNewAssigned, numNewReceived, numNewSendback } =
    data;

  return (
    <Card sx={{ margin: 3, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={5} display="flex">
          <MDTypography variant="h3">
            {intl.get('home_text_welcome_back')}
          </MDTypography>
          <MDTypography variant="h3">{userFullName}</MDTypography>
        </MDBox>
        <br />
        <br />
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDBox
            px={3}
            mb={5}
            width="80%"
            display="flex"
            justifyContent="space-around"
            alignItems="center">
            {userType === 'doctor' && (
              <Fragment>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column' }}
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                  component={NavLink}
                  to={
                    userType === 'doctor'
                      ? '/received/history/'
                      : '/received/my-task'
                  }>
                  <MDBadge
                    badgeContent={numNewAssigned}
                    color="error"
                    size="lg"
                    circular>
                    <MDBox
                      component="img"
                      src={iconNewAssigned}
                      alt="new_assigned"
                      width="10rem"
                      mr={1}
                      pb={1}
                    />
                  </MDBadge>
                  <MDTypography variant="h3">
                    {intl.get('home_link_new_assigned_issues')}
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column' }}
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                  component={NavLink}
                  to="/in-charge">
                  <MDBadge
                    badgeContent={numberNewInCharge}
                    color="error"
                    size="lg"
                    circular>
                    <MDBox
                      component="img"
                      src={iconNewInCharge}
                      alt="new_assigned"
                      width="10rem"
                      mr={1}
                      pb={1}
                    />
                  </MDBadge>
                  <MDTypography variant="h3">
                    {intl.get('home_link_new_in_charge_issues')}
                  </MDTypography>
                </MDBox>
              </Fragment>
            )}
            {['staff', 'manager'].includes(userType) && (
              <Fragment>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column' }}
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                  component={NavLink}
                  to="/received/my-task">
                  <MDBadge
                    badgeContent={numNewReceived}
                    color="error"
                    size="lg"
                    circular>
                    <MDBox
                      component="img"
                      src={iconNewReceived}
                      alt="new_received"
                      width="10rem"
                      mr={1}
                      pb={1}
                    />
                  </MDBadge>
                  <MDTypography variant="h3">
                    {intl.get('home_link_new_received_issues')}
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection={{ xs: 'column' }}
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                  component={NavLink}
                  to="/sent">
                  <MDBadge
                    badgeContent={numNewSendback}
                    color="error"
                    size="lg"
                    circular>
                    <MDBox
                      component="img"
                      src={iconNewSendback}
                      alt="new_sendback"
                      width="10rem"
                      mr={1}
                      pb={1}
                    />
                  </MDBadge>
                  <MDTypography variant="h3">
                    {intl.get('home_link_new_sendback_issues')}
                  </MDTypography>
                </MDBox>
              </Fragment>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default withRouter(DashboardHome);
