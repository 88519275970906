import 'date-fns';
import React from 'react';
import intl from 'react-intl-universal';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const propType = {
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.func.isRequired,
};

export default function MaterialUIPickers({ selectedDate, setSelectedDate }) {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    // TOOD This library is no longer mantained
    // https://github.com/mui/material-ui-pickers
    // use @mui/x-date-pickers
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container justifyContent="space-around">
        <DatePicker
          displayStaticWrapperAs="desktop"
          variant="inline"
          openTo="month"
          views={['year', 'month']}
          label={intl.get('billing_label_month_selector_note')}
          value={selectedDate}
          renderInput={(params) => <TextField {...params} />}
          onChange={handleDateChange}
        />
      </Grid>
    </LocalizationProvider>
  );
}

MaterialUIPickers.propTypes = propType;
