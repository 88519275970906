import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid } from '@material-ui/core';

import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

import MaterialUIPickers from '../shared/MonthPicker';
import SummaryTable from './SummaryTable';
import DetailedTable from './DetailedTable';

import {
  createSrcOrgNamesDict,
  createPricesDict,
  createQuotaSrcOrgsDict,
} from '../shared/utils';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

const propTypes = {};

const DashboardBillingForReceivedCases = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [loaded, setLoaded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSrcOrgId, setSelectedSrcOrgId] = useState(-1);
  const [tableType, setTableType] = useState('summary');
  const [issuesData, setIssuesData] = useState([]);
  const [pricesDict, setPricesDict] = useState({});
  const [srcOrgNamesDict, setSrcOrgNamesDict] = useState({});
  const [dstOrg, setDstOrg] = useState({});
  const [quotaSrcOrgsDict, setQuotaSrcOrgsDict] = useState({});

  const dstOrgId = localData.get('currentOrganizationId');

  useEffect(() => {
    setLoaded(false);

    const apiVariables = {
      params: {
        organizationId: parseInt(dstOrgId),
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth(),
      },
    };

    api.get('/api/billing_received_tasks', apiVariables).then(async (data) => {
      // get raw data of issues, prices and orgs
      const { issues, prices, orgs, dstOrg } = data;

      const _issues = issues;
      setIssuesData(_issues);

      const _pricesDict = createPricesDict(prices);
      setPricesDict(_pricesDict);

      const _srcOrgNamesDict = createSrcOrgNamesDict(issues, orgs);
      setSrcOrgNamesDict(_srcOrgNamesDict);

      const _dstOrg = dstOrg;
      setDstOrg(_dstOrg);

      const _quotaSrcOrgsDict = createQuotaSrcOrgsDict(dstOrg.quotaSrcOrgs);
      setQuotaSrcOrgsDict(_quotaSrcOrgsDict);

      setLoaded(true);
    });
  }, [selectedDate, dstOrgId, tableType]);

  if (!loaded) {
    return <div />;
  }

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item>
          <MDBox ml={4}>
            <MDTypography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginRight: '18px',
                textAlign: 'right',
                alignSelf: 'stretch',
              }}>
              {`${intl.get('billing_text_select_billing_period')}: `}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={2}>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MaterialUIPickers
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            {tableType === 'summary' && (
              <SummaryTable
                selectedDate={selectedDate}
                issuesData={issuesData}
                pricesDict={pricesDict}
                srcOrgNamesDict={srcOrgNamesDict}
                selectedSrcOrgId={selectedSrcOrgId}
                setSelectedSrcOrgId={setSelectedSrcOrgId}
                setTableType={setTableType}
                dstOrg={dstOrg}
                quotaSrcOrgsDict={quotaSrcOrgsDict}
              />
            )}

            {tableType === 'detailed' && (
              <DetailedTable
                issuesData={issuesData}
                pricesDict={pricesDict}
                srcOrgNamesDict={srcOrgNamesDict}
                selectedSrcOrgId={selectedSrcOrgId}
                setTableType={setTableType}
                dstOrg={dstOrg}
              />
            )}
          </ThemeProvider>
        </MDBox>
      </Card>
    </Fragment>
  );
};

DashboardBillingForReceivedCases.propTypes = propTypes;

export default withRouter(DashboardBillingForReceivedCases);
