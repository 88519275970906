import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import api from 'shared/utils/api';
import { Grid, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

import useStyles from './styles';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import FormField from 'pages/parts/FormField';
import { ConfirmModal } from 'shared/modals/confirmModal';

const propTypes = {
  quotaSrcOrg: PropTypes.object,
  priceSrcOrgs: PropTypes.array,
};

const DashboardOrganizationSettingsClientManagementClientSettings = ({
  quotaSrcOrg,
  priceSrcOrgs,
}) => {
  const classes = useStyles();

  const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  const [formState, setFormState] = useState({});
  const [monthlyInvoiceDateValue, setMonthlyInvoiceDateValue] = useState(1);

  useEffect(() => {
    setFormState({
      quotaOrgId: quotaSrcOrg.id,
      srcOrgId: quotaSrcOrg.srcOrgId,
      dstOrgId: quotaSrcOrg.dstOrgId,
      quota: quotaSrcOrg.quota,
      monthlyInvoiceDate: quotaSrcOrg.monthlyInvoiceDate,
      priceAndStates: priceSrcOrgs.map((price, index) => {
        return { price: { ...price }, state: 'read', formerIndex: index };
      }),
    });
    setMonthlyInvoiceDateValue(quotaSrcOrg.monthlyInvoiceDate);
  }, [quotaSrcOrg, priceSrcOrgs]);

  const handleChange = (key) => async (event) => {
    const newFormState = { ...formState, [key]: event.target.value };
    setFormState({ ...newFormState });
  };

  const billingDateOptions = Array.from({ length: 28 }, (_, i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString(),
  }));

  const handleChangeBillingDate = (newValue) => {
    const newFormState = { ...formState, monthlyInvoiceDate: newValue };
    setFormState({ ...newFormState });
  };

  const handleUpdateClick = async (event) => {
    setShowConfirmBeforeSend(true);
  };

  const handleConfirmClick = async (event) => {
    setShowConfirmBeforeSend(false);
    await sendToServer();
  };

  const wasChanged = (formerValue, currentValue) => {
    if (formerValue.toString() === currentValue.toString()) {
      return false;
    }
    return true;
  };

  const generateConfirmWindowContents = () => {
    return (
      <Grid
        spacing={3}
        container
        style={{ marginTop: '15px', marginLeft: '8px' }}>
        {wasChanged(quotaSrcOrg.quota, formState.quota) && (
          <Grid columns={{ xs: 12 }}>
            <MDTypography>
              {intl.get('organization_settings_client_management_label_quota') +
                ': ' +
                quotaSrcOrg.quota +
                ' ==> ' +
                formState.quota}
            </MDTypography>
          </Grid>
        )}
        {wasChanged(
          quotaSrcOrg.monthlyInvoiceDate,
          formState.monthlyInvoiceDate
        ) && (
          <Grid columns={{ xs: 12 }}>
            <MDTypography>
              {intl.get(
                'organization_settings_client_management_label_billing_date'
              ) +
                ': ' +
                quotaSrcOrg.monthlyInvoiceDate +
                ' ==> ' +
                formState.monthlyInvoiceDate}
            </MDTypography>
          </Grid>
        )}
        {formState.priceAndStates.map((priceAndState, index) => {
          if (priceAndState.state === 'delete') {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_delete'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else if (priceAndState.state === 'create') {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_add'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else if (priceAndState.state === 'update') {
            return (
              <Grid columns={{ xs: 12 }} key={index}>
                <MDTypography>
                  {intl.get(
                    'organization_settings_client_management_button_update'
                  ) +
                    ': ' +
                    priceAndState.price.modality +
                    ' ' +
                    priceAndState.price.bodypart +
                    ': ￥' +
                    priceSrcOrgs[priceAndState.formerIndex].price +
                    ' ==> ￥' +
                    priceAndState.price.price}
                </MDTypography>
              </Grid>
            );
          } else {
            return <div key={index}></div>;
          }
        })}
      </Grid>
    );
  };

  const sendToServer = async () => {
    const variables = {
      params: {
        organizationId: quotaSrcOrg.dstOrgId,
      },
      data: { ...formState },
    };

    api.post('/api/organizations/client_management', variables).then(
      (data) => {
        window.location.reload();
      },
      (error) => {
        console.error(error);
        console.log('ERROR in CLIENT MANAGEMENT');
      }
    );
  };

  return (
    <MDBox>
      <Grid spacing={3} container columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <Grid container columns={{ xs: 12 }}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                {intl.get('organization_settings_client_management_text_quota')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justifyContent="center"
            columns={{ xs: 12 }}>
            <Grid item xs={12}>
              <FormField
                id="quota"
                name="quota"
                label={intl.get(
                  'organization_settings_client_management_label_quota'
                )}
                variant="outlined"
                value={formState.quota ?? 0}
                onChange={handleChange('quota')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classes.block}
        spacing={3}
        container
        columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <Grid container columns={{ xs: 12 }}>
            <MDBox mb={1}>
              <MDTypography variant="h5" gutterBottom>
                {intl.get(
                  'organization_settings_client_management_text_billing_date'
                )}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justifyContent="center"
            columns={{ xs: 12 }}>
            <Grid item xs={12}>
              {/* TODO */}
              <Autocomplete
                id="billingDate"
                value={monthlyInvoiceDateValue.toString()}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleChangeBillingDate(newValue.value);
                    setMonthlyInvoiceDateValue(newValue.value);
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value;
                }}
                options={billingDateOptions}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    variant="outlined"
                    label={intl.get(
                      'organization_settings_client_management_label_billing_date'
                    )}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Don't remove the folling code! Need improvement later */}

      {/* <Grid className={classes.block} spacing={3} container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">
                {intl.get(
                  "organization_settings_client_management_text_prices"
                )}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            justify="center"
            xs={12}
          >
            {formState.priceAndStates.map((priceAndState, index) => {
              return (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={3}
                    alignItems="flex-start"
                    justify="center"
                    xs={12}
                  >
                    <Grid item xs={3}>
                      <MDTypography>
                        {priceAndState.price.modality}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <MDTypography>
                        {priceAndState.price.bodypart}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        id={"price-" + index}
                        name={"price-" + index}
                        label={"price"}
                        fullWidth
                        variant="outlined"
                        value={priceAndState.price.price}
                        size="small"
                        onChange={handlePriceChange(index)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {(priceAndState.state === "read" ||
                        priceAndState.state === "create") && (
                        <MDButton
                          variant="contained"
                          color="info"
                          fullWidth
                          onClick={handleDeleteClick(index)}
                        >
                          {intl.get(
                            "organization_settings_client_management_button_delete"
                          )}
                        </MDButton>
                      )}
                      {priceAndState.state === "delete" && (
                        <MDButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleUndoClick(index)}
                        >
                          {intl.get(
                            "organization_settings_client_management_button_un_delete"
                          )}
                        </MDButton>
                      )}
                      {priceAndState.state === "update" && (
                        <MDButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleUndoClick(index)}
                        >
                          {intl.get(
                            "organization_settings_client_management_button_un_update"
                          )}
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
                justify="center"
                xs={12}
              >
                <Grid item xs={3}>
                  <Autocomplete
                    id="newPriceModality"
                    onChange={(event, newValue) => {
                      // if (newValue && newValue.id)
                      //   handleNewPriceChange2("modality", newValue);
                      if (newValue) handleNewPriceChange2("modality", newValue);
                    }}
                    // options={getNewPriceModalityOptions(
                    //   selectModalityOptions[newPriceState.bodypart]
                    // )}
                    options={newPriceModalityOptions}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        variant="outlined"
                        label={intl.get(
                          "organization_settings_client_management_label_modality"
                        )}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormFieldHelperText>
                    {intl.get(
                      "organization_settings_client_management_helper_modality"
                    )}
                  </FormFieldHelperText>
                </Grid>

                <Grid item xs={3}>
                  <Autocomplete
                    id="newPriceBodypart"
                    onChange={(event, newValue) => {
                      // if (newValue && newValue.id)
                      //   handleNewPriceChange2("bodypart", newValue);
                      if (newValue) handleNewPriceChange2("bodypart", newValue);
                    }}
                    // options={getNewPriceModalityOptions(
                    //   selectBodypartOptions[newPriceState.modality]
                    // )}
                    options={newPriceBodypartOptions}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        variant="outlined"
                        label={intl.get(
                          "organization_settings_client_management_label_bodypart"
                        )}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormFieldHelperText>
                    {intl.get(
                      "organization_settings_client_management_helper_bodypart"
                    )}
                  </FormFieldHelperText>
                </Grid>

                <Grid item xs={3}>
                  <FormField
                    id={"newPrice"}
                    name={"newPrice"}
                    label={"price"}
                    fullWidth
                    variant="outlined"
                    onChange={handleNewPriceChange("price")}
                    value={newPriceState.price}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3}>
                  <MDButton
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={handleAddClick()}
                  >
                    {intl.get(
                      "organization_settings_client_management_button_add"
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Divider /> */}
      <Grid
        className={classes.block}
        spacing={3}
        container
        alignItems="flex-start"
        justifyContent="center"
        columns={{ xs: 12 }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateClick}>
            {intl.get('organization_settings_client_management_button_update')}
          </Button>
        </Grid>
      </Grid>

      <ConfirmModal
        isOpened={showConfirmBeforeSend}
        onCancel={() => setShowConfirmBeforeSend(false)}
        onConfirm={handleConfirmClick}
        title={intl.get(
          'organization_settings_client_settings_modal_text_confirm_title'
        )}
        bodyText={intl.get(
          'organization_settings_client_settings_modal_text_confirm_hint'
        )}
        confirmButtonText={intl.get(
          'organization_settings_client_settings_modal_button_submit'
        )}
        cancelButtonText={intl.get(
          'organization_settings_client_settings_modal_button_cancel'
        )}>
        <MDBox>
          <Grid
            spacing={3}
            container
            justifyContent="flex-end"
            alignItems="flex-end">
            <Grid xs={12} item>
              {showConfirmBeforeSend && generateConfirmWindowContents()}
            </Grid>
          </Grid>
        </MDBox>
      </ConfirmModal>
    </MDBox>
  );
};

DashboardOrganizationSettingsClientManagementClientSettings.propTypes =
  propTypes;

export default withRouter(
  DashboardOrganizationSettingsClientManagementClientSettings
);
