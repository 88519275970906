import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import ManualJaJP from './ManualJaJP';
import localData from 'shared/utils/localData';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';

const DashboardManual = () => {
  const preferredLanguage = localData.get('userPreferredLanguage');

  return (
    <Fragment>
      <Card
        sx={{
          margin: 4,
          marginTop: 3,
          overflow: 'visible',
          height: '92.8%',
          alignItems: 'center',
        }}>
        <MDBox
          py={3}
          px={3}
          mt={3}
          mx={3}
          sx={{ height: '100%', width: '100%' }}>
          {preferredLanguage === 'ja-JP' && <ManualJaJP />}
          {preferredLanguage === 'en-US' && <ManualJaJP />}
          {preferredLanguage === 'zh-CN' && <ManualJaJP />}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardManual);
