import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals'; // to be used later
import CornerstoneService from './services/CornerstoneService';
import { initLocale, LANGUAGE_CODE } from '../src/shared/utils/intl';
import { MaterialUIControllerProvider } from './context';
import { browserStorage } from 'shared/utils/storage';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
    // storage: window.sessionStorage,
    storage: browserStorage,
    // authenticationFlowType: 'CUSTOM_AUTH' | 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' ,
    authenticationFlowType: 'CUSTOM_AUTH',
    // authenticationFlowType: 'USER_SRP_AUTH',
  },
});

CornerstoneService.initCornerstone();

// Init intl to the default language Japanese.
initLocale(LANGUAGE_CODE.JAPANESE);

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
// To be used later
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
