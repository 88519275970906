import { axiosForS3PresignedUrl } from 'shared/utils/axiosInstance';
import {
  setUploadingProgressContext,
  setUploadedBytesContext,
} from 'context/NewIssueContext';

export const MB = 1024 * 1024;
export const GB = 1024 * MB;
export const FILE_CHUNK_SIZE = 100 * MB;

export const uploadParts = async ({
  file,
  presignedS3Url,
  batchTotalBytes,
  missionId,
  controllerUploadMissions,
  dispatchUploadMissions,
  axiosUploadController,
}) => {
  const keys = Object.keys(presignedS3Url);
  const resParts = [];
  for (const indexStr of keys) {
    const uploadedBytesBase =
      controllerUploadMissions.missions[missionId].uploadedBytes;
    const index = parseInt(indexStr);
    const start = index * FILE_CHUNK_SIZE;
    const end = (index + 1) * FILE_CHUNK_SIZE;
    const blob =
      index < keys.length ? file.slice(start, end) : file.slice(start);
    const resPart = await axiosForS3PresignedUrl({
      method: 'PUT',
      url: presignedS3Url[index],
      data: blob,
      signal: axiosUploadController.signal,
      onUploadProgress: (data) => {
        const gap = data.total - data.loaded;
        if (gap > 0 && Math.random() > 0.25) return;
        const newUploadedBytes = uploadedBytesBase + data.loaded;
        const uploadingProgress = Math.round(
          100 * (newUploadedBytes / batchTotalBytes)
        );

        setUploadedBytesContext(dispatchUploadMissions, {
          missionId,
          value: newUploadedBytes,
        });
        setUploadingProgressContext(dispatchUploadMissions, {
          missionId,
          value: uploadingProgress,
        });
      },
    });

    resParts.push(resPart);
  }

  return resParts.map((part, index) => ({
    ETag: part.headers.etag,
    PartNumber: index + 1,
  }));
};
