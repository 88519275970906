import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';
import { PageLoader, PageError, Modal } from 'components';

// components and parts
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import Details from '../Details';
import Delete from '../Delete';
import New from './New';
import CustomSearchRender from 'components/CustomSearchRender/admin.js';

const propTypes = {};

const IWGDashboardUsers = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [userDetailsModalOpen, setUserDetailsModalOpen] = useState(false);
  const [userIdSelected, setUserIdSelected] = useState(-1);
  const [userDataSelected, setUserDataSelected] = useState({});
  const [userNewModalOpen, setUserNewModalOpen] = useState(false);
  const [userDeleteModalOpen, setUserDeleteModalOpen] = useState(false);

  const [{ data, error }, fetchUsers] = useApi.get('/api/admin/users', {});
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const getChangableUserData = (user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userType: user.userType,
      preferredLanguage: user.preferredLanguage,
      preferredTimezone: user.preferredTimezone,
    };
  };

  const handleOpenUserDetails = (user) => {
    setUserIdSelected(user.id);
    setUserDataSelected((prevState) => ({
      ...getChangableUserData(user),
    }));
    setUserDetailsModalOpen(true);
  };

  const handleUpdateUser = async (userId, updatedUserJSON) => {
    const updatedUserData = JSON.parse(updatedUserJSON);

    await api.put('/api/admin/users', {
      data: {
        ids: [userId],
        updatedData: [updatedUserData],
      },
    });
    await fetchUsers();
  };

  const handleOpenDeleteUser = (user) => {
    setUserIdSelected(user.id);
    setUserDataSelected((prevState) => ({
      ...getChangableUserData(user),
    }));
    setUserDeleteModalOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    await api.delete('/api/admin/users', {
      data: {
        ids: [userId],
      },
    });

    await fetchUsers();
  };

  data.sort((user1, user2) => {
    if (user1.id > user2.id) return 1;
    if (user1.id < user2.id) return -1;
    return 0;
  });

  const tableData = data.map((user) => {
    return [user.id, user.firstName, user.lastName, user.username, user, user];
  });

  const columns = [
    {
      name: 'id',
      label: 'User Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenUserDetails(user);
                }}>
                Details
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteUser(user);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPageOptions: [20, 50, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            <Grid container item xs={2} columnSpacing={3}>
              <Grid item>
                <h2>Users</h2>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setUserNewModalOpen(true);
                  }}>
                  New
                </MDButton>
              </Grid>
            </Grid>
          }
        />
      );
    },
    // TODO: add a customeToobarSelect
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h2>Users</h2>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setUserNewModalOpen(true);
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>

        {userNewModalOpen && (
          <Modal
            isOpen
            testId="modal:admin-user-new"
            width={1040}
            withCloseIcon={true}
            onClose={() => setUserNewModalOpen(false)}
            renderContent={(modal) => (
              <New close={modal.close} fetchUsers={fetchUsers} />
            )}
          />
        )}
        {userDetailsModalOpen && (
          <Modal
            isOpen
            testId="modal:admin-user-details"
            width={1040}
            withCloseIcon={true}
            onClose={() => setUserDetailsModalOpen(false)}
            renderContent={(modal) => (
              <Details
                dataType="User"
                dataId={userIdSelected}
                data={userDataSelected}
                updateData={handleUpdateUser}
              />
            )}
          />
        )}
        {userDeleteModalOpen && (
          <Modal
            isOpen
            testId="modal:admin-user-delete"
            width={1040}
            withCloseIcon={true}
            onClose={() => setUserDeleteModalOpen(false)}
            renderContent={(modal) => (
              <Delete
                dataType="User"
                dataId={userIdSelected}
                data={userDataSelected}
                deleteData={handleDeleteUser}
                close={setUserDeleteModalOpen}
              />
            )}
          />
        )}
      </Card>
    </Fragment>
  );
};

IWGDashboardUsers.propTypes = propTypes;

export default withRouter(IWGDashboardUsers);
