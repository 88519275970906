import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { getIssuePriority } from 'shared/constants/issues';
import { IssuePriorityIcon2 } from 'components';
import { SectionTitle } from '../Styles';
import { Priority, Label } from './Styles';

const propTypes = {
  issue: PropTypes.object.isRequired,
};

const ProjectBoardIssueDetailsPriority = ({ issue }) => (
  <Fragment>
    <SectionTitle>{intl.get('issue_details_text_priority')}</SectionTitle>
    {renderPriorityItem(issue.priority, true)}
  </Fragment>
);

const renderPriorityItem = (priority, isValue) => (
  <Priority isValue={isValue}>
    <IssuePriorityIcon2 priority={priority} />
    <Label>{getIssuePriority(priority)}</Label>
  </Priority>
);

ProjectBoardIssueDetailsPriority.propTypes = propTypes;

export default ProjectBoardIssueDetailsPriority;
