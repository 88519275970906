import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import { calculateTotalPrice } from '../../shared/utils';

import CustomSearchRender from 'components/CustomSearchRender';

const propTypes = {
  issuesData: PropTypes.array,
  pricesDict: PropTypes.object,
  dstOrgNamesDict: PropTypes.object,
  setSelectedDstOrgId: PropTypes.func,
  setTableType: PropTypes.func,
};

const DashboardBillingForSentCasesSummaryTable = ({
  issuesData,
  pricesDict,
  dstOrgNamesDict,
  setSelectedDstOrgId,
  setTableType,
}) => {
  const getSummaryTableData = (_issuesData, _pricesDict, _dstOrgNamesDict) => {
    const data = [];

    for (let dstOrgId in _dstOrgNamesDict) {
      const rowData = [];

      // destination organization name
      const dstOrgName = _dstOrgNamesDict[dstOrgId];

      // calculate the total price
      const issues = _issuesData.filter(
        (item) => item.dstOrgId.toString() === dstOrgId
      );
      const totalPrice = calculateTotalPrice(issues, _pricesDict);

      // calculate the update date
      // TODO: Determine how to calculate the update date
      const updateDate = 'Update date';

      rowData.push(dstOrgName);
      rowData.push(totalPrice);
      rowData.push(dstOrgId);
      rowData.push(updateDate);

      data.push(rowData);
    }

    return data;
  };

  const data = getSummaryTableData(issuesData, pricesDict, dstOrgNamesDict);

  const columns = [
    {
      name: 'to_organization_name',
      label: intl.get('billing_for_sent_cases_label_column_sent_to'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total_price',
      label: intl.get('billing_for_sent_cases_label_column_total_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const totalPrice = value;
          return (
            <div>
              <p>￥{totalPrice}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'details',
      label: intl.get('billing_for_sent_cases_label_column_details'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedDstOrgId(value);
                  setTableType('detailed');
                }}>
                {intl.get('billing_for_sent_cases_button_go_to_details')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'update_date',
      label: intl.get('billing_for_sent_cases_label_column_update_date'),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    filterType: 'textField',
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 25, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get('billing_for_sent_cases_text_sent_cases')}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
        // columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      <MUIDataTable
        title={intl.get('billing_for_sent_cases_text_sent_cases')}
        data={data}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};

DashboardBillingForSentCasesSummaryTable.propTypes = propTypes;

export default DashboardBillingForSentCasesSummaryTable;
