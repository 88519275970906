import React, { Fragment, useState, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDLoadingButton from 'components/MDLoadingButton';

// Authentication layout components
import BasicLayout from 'pages/Welcome/components/BasicLayout';

// Images
import bgImage from 'assets/images/background/bg-0.png';
import newBgImage from 'assets/images/bg-login-bai.jpeg';

import { validatePassword } from 'shared/utils/misc';

// For change input background color
const useStyles = makeStyles((theme) => ({
  input: {
    background: '#FFFFFF !important',
    color: 'black !important',
  },
}));

const initialState = {
  // page 1: enter username and request for verification code
  codeSent: false, // show page 1
  codeDeliveryFailed: false,
  codeDeliveryFailedReason: '',
  invalidEmail: false, // show message on page 1
  tooManyAttempts: false, // show message on page 1
  isSendingCode: false, // on click

  // page 2: enter code and reset password
  passwordIsReset: false, // show page 2 or 3
  emptyEntry: false, // show warning message;
  invalidResetForm: false,
  invalidResetFormMessage: '', // "inconsistentPassword" / "emptyCode" / "tooSimplePassword"
  isSettingPassword: false, // on click
  passwordResetFailed: false,
  passwordResetFailedReason: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'submitEmail': {
      return {
        ...state,
        isSendingCode: true,
      };
    }
    case 'codeSent': {
      return {
        ...state,
        codeSent: true,
        isSendingCode: false,
        codeDeliveryFailed: false,
        codeDeliveryFailedReason: '',
      };
    }
    case 'codeDeliveryFailed': {
      return {
        ...state,
        isSendingCode: false,
        codeSent: false,
        codeDeliveryFailed: true,
        codeDeliveryFailedReason: action.message,
      };
    }
    case 'clickPasswordResetButton': {
      return {
        ...state,
        isSettingPassword: true,
      };
    }
    case 'initializeResetForm': {
      return {
        ...state,
        isSettingPassword: false,
        invalidResetForm: false,
        invalidResetFormMessage: '',
        passwordResetFailed: false,
        passwordResetFailedReason: '',
      };
    }
    case 'invalidResetForm': {
      return {
        ...state,
        invalidResetForm: true,
        invalidResetFormMessage: action.message,
        isSettingPassword: false,
      };
    }
    case 'passwordResetSuccess': {
      return {
        ...state,
        isSettingPassword: false,
        passwordIsReset: true,
        invalidResetForm: false,
        passwordResetFailed: false,
        passwordResetFailedReason: '',
      };
    }
    case 'passwordResetFail': {
      return {
        ...state,
        isSettingPassword: false,
        invalidResetForm: false,
        passwordIsReset: false,
        passwordResetFailed: true,
        passwordResetFailedReason: action.message,
      };
    }
    case 'goBackToPage1': {
      return {
        ...state,
        codeSent: false,
        isSettingPassword: false,
        isSendingCode: false,
        invalidResetForm: false,
        passwordIsReset: false,
      };
    }
    default:
      return;
  }
};

export default function ResetPassword(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fields, setFields] = useState({
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const classes = useStyles();

  const loginMode = 0;

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSendCodeClick = async (event) => {
    event.preventDefault();
    dispatch({ type: 'submitEmail' });
    try {
      await Auth.forgotPassword(fields.email);
      dispatch({ type: 'codeSent' });
    } catch (error) {
      dispatch({ type: 'codeDeliveryFailed', message: error.code });
    }
  };

  const handleResetPasswordClick = async (event) => {
    event.preventDefault();

    // check if the reset form entries are okay.
    dispatch({ type: 'initializeResetForm' });

    if (fields.code.length < 1) {
      dispatch({ type: 'invalidResetForm', message: 'emptyCode' });
      return;
    } else if (!validatePassword(fields.password)) {
      dispatch({ type: 'invalidResetForm', message: 'tooSimplePassword' });
      return;
    } else if (fields.password !== fields.confirmPassword) {
      dispatch({ type: 'invalidResetForm', message: 'inconsistentPassword' });
      return;
    }

    dispatch({ type: 'clickPasswordResetButton' });

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      dispatch({ type: 'passwordResetSuccess' });
    } catch (error) {
      dispatch({ type: 'passwordResetFail', message: error.code }); // "CodeMismatchException"
    }
  };

  const renderCodeDeliveryErrorMessage = (reason) => {
    switch (reason) {
      case 'LimitExceededException': {
        return (
          <Fragment>
            <Typography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              試行回数は上限を超えました。
            </Typography>
            <Typography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              時間を置いてもう一度お試しください。
            </Typography>
          </Fragment>
        );
      }
      case 'UserNotFoundException': {
        return (
          <Typography
            variant="body2"
            fontWeight="light"
            style={{ color: 'red' }}>
            無効なユーザーID
          </Typography>
        );
      }
      default:
        return;
    }
  };

  const renderInvalidResetFormErrorMessage = (reason) => {
    switch (reason) {
      case 'emptyCode': {
        return (
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              認証コードが入力されていません
            </MDTypography>
          </MDBox>
        );
      }
      case 'inconsistentPassword': {
        return (
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              パスワードが一致しません
            </MDTypography>
          </MDBox>
        );
      }
      case 'tooSimplePassword': {
        return (
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              {' '}
              新しいパスワードは、下記の条件を満たす必要があります：
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              {'\u2022'} 半角8文字以上
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              {'\u2022'} 数字が含まれる{' '}
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              {'\u2022'} 大文字の英字が含まれる{' '}
            </MDTypography>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red', marginBottom: '10px' }}>
              {'\u2022'} 小文字の英字が含まれる{' '}
            </MDTypography>
          </MDBox>
        );
      }
      default:
        return;
    }
  };

  const renderPasswordResetFailedErrorMessage = (reason) => {
    switch (reason) {
      case 'CodeMismatchException': {
        return (
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              認証コードが無効です。正しい認証コードを入力してください。
            </MDTypography>
          </MDBox>
        );
      }
      case 'ExpiredCodeException': {
        // fall back to page 1
        return (
          <MDBox mb={1}>
            <MDTypography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              認証コードの有効期限が切れています。最初からやり直してください。
            </MDTypography>
          </MDBox>
        );
      }
      case 'LimitExceededException': {
        // fall back to page 1
        return (
          <Fragment>
            <Typography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              試行回数は上限を超えました。
            </Typography>
            <Typography
              variant="body2"
              fontWeight="light"
              style={{ color: 'red' }}>
              時間を置いてもう一度お試しください。
            </Typography>
          </Fragment>
        );
      }
      default:
        return;
    }
  };

  function renderRequestCodeForm(props) {
    if (loginMode === 0) {
      return (
        // <Card>
        <Card
          id="BasicLayoutChildrenCard"
          sx={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
          <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
            <MDBox mb={3}>
              <MDTypography style={{ color: 'white' }}>
                ユーザーIDを入力してください:
              </MDTypography>
            </MDBox>
            <MDBox component="form" role="form" onSubmit={handleSendCodeClick}>
              <MDBox mb={2}>
                <MDInput
                  required
                  fullWidth
                  name="email"
                  value={fields.email}
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MDBox>

              {state.codeDeliveryFailed &&
                renderCodeDeliveryErrorMessage(state.codeDeliveryFailedReason)}

              <MDBox mt={4} mb={1}>
                <MDLoadingButton
                  loadingPosition="start"
                  loading={state.isSendingCode}
                  // disabled={!validateCodeForm() || state.isSendingCode}
                  type="submit"
                  variant="gradient"
                  // color="info"
                  // sx={{backgroundColor: "#C81414"}}
                  sx={{
                    backgroundColor: '#194796',
                    '&:hover': {
                      backgroundColor: '#194796',
                    },
                    '&:disabled': {
                      backgroundColor: '#194796',
                    },
                  }}
                  fullWidth
                  // textAlign='center'
                  // sx={{ width: 1/2 }}
                >
                  <MDTypography variant="h5" fontWeight="bold" color="white">
                    {state.isSendingCode ? 'お待ちください...' : '送信'}
                  </MDTypography>
                </MDLoadingButton>
              </MDBox>

              <MDBox mt={2} mb={1}>
                <Link to="/">
                  <MDTypography
                    component="span"
                    variant="body2"
                    style={{ color: 'white' }}>
                    ログイン画面へ戻る
                  </MDTypography>
                </Link>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      );
    } else {
      return (
        // <Card>
        <Card id="BasicLayoutChildrenCard" sx={{ backgroundColor: '#8B8B8B' }}>
          <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
            <MDBox mb={3}>
              <MDTypography style={{ color: 'white' }}>
                ユーザーIDを入力してください:
              </MDTypography>
            </MDBox>
            <MDBox component="form" role="form" onSubmit={handleSendCodeClick}>
              <MDBox mb={2}>
                <MDInput
                  required
                  fullWidth
                  name="email"
                  value={fields.email}
                  onChange={handleInput}
                  sx={{ input: { color: 'black !important' } }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </MDBox>
              {state.codeDeliveryFailed &&
                renderCodeDeliveryErrorMessage(state.codeDeliveryFailedReason)}

              <MDBox mt={4} mb={1}>
                <MDLoadingButton
                  loadingPosition="start"
                  loading={state.isSendingCode}
                  // disabled={!validateCodeForm() || state.isSendingCode}
                  type="submit"
                  variant="gradient"
                  // color="info"
                  // sx={{backgroundColor: "#C81414"}}
                  sx={{
                    backgroundColor: '#C81414',
                    '&:hover': {
                      backgroundColor: '#C81414',
                    },
                    '&:disabled': {
                      backgroundColor: '#C81414',
                    },
                  }}
                  fullWidth
                  // textAlign='center'
                  // sx={{ width: 1/2 }}
                >
                  <MDTypography variant="h5" fontWeight="bold" color="white">
                    {state.isSendingCode ? 'お待ちください...' : '送信'}
                  </MDTypography>
                </MDLoadingButton>
              </MDBox>
              <MDBox mt={2} mb={1}>
                <Link to="/">
                  <MDTypography
                    component="span"
                    variant="body2"
                    style={{ color: 'white' }}>
                    ログイン画面へ戻る
                  </MDTypography>
                </Link>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      );
    }
  }

  function renderNewPasswordForm() {
    return (
      // <Card>
      <Card id="BasicLayoutChildrenCard" sx={{ backgroundColor: '#8B8B8B' }}>
        <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
          <MDBox mb={3}>
            <MDTypography style={{ color: 'white' }}>
              {fields.email}{' '}
              のメールアドレスに認証コードが送信されました。以下にコードを入力してください：
            </MDTypography>
          </MDBox>
          <MDBox
            component="form"
            role="form"
            onSubmit={handleResetPasswordClick}>
            <MDBox mb={2}>
              <MDInput
                required
                fullWidth
                name="code"
                value={fields.code}
                onChange={handleInput}
                sx={{ input: { color: 'black !important' } }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </MDBox>

            <MDBox mb={3}>
              <MDTypography style={{ color: 'white' }}>
                新しいパスワード:
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={fields.password}
                onChange={handleInput}
                sx={{ input: { color: 'black !important' } }}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        color="info">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>

            <MDBox mb={3}>
              <MDTypography style={{ color: 'white' }}>
                パスワード確認
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={fields.confirmPassword}
                onChange={handleInput}
                sx={{ input: { color: 'black !important' } }}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        color="info">
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>

            {state.invalidResetForm &&
              renderInvalidResetFormErrorMessage(state.invalidResetFormMessage)}
            {state.passwordResetFailed &&
              renderPasswordResetFailedErrorMessage(
                state.passwordResetFailedReason
              )}

            <MDBox mt={4} mb={1}>
              <MDLoadingButton
                loading={state.isSettingPassword}
                disabled={state.isSettingPassword}
                type="submit"
                variant="gradient"
                fullWidth
                sx={{
                  backgroundColor: '#C81414',
                  '&:hover': {
                    backgroundColor: '#C81414',
                  },
                  '&:disabled': {
                    backgroundColor: '#C81414',
                  },
                }}>
                <MDTypography variant="h5" fontWeight="bold" color="white">
                  {state.isSettingPassword ? 'お待ちください...' : '送信'}
                </MDTypography>
              </MDLoadingButton>
            </MDBox>

            <MDBox mt={2} mb={1}>
              <MDTypography
                component="span"
                variant="body2"
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => dispatch({ type: 'goBackToPage1' })}>
                前のページへ戻る
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    );
  }

  function renderSuccessMessage() {
    return (
      // <Card>
      <Card id="BasicLayoutChildrenCard" sx={{ backgroundColor: '#8B8B8B' }}>
        <MDBox pt={4} pb={3} px={3} sx={{ width: '100%' }}>
          <MDBox mb={3}>
            <MDTypography variant="h4" style={{ color: 'white' }}>
              パスワードがリセットされました。
            </MDTypography>
          </MDBox>

          <MDBox mt={4} mb={1}>
            <Link to="/">
              <MDButton
                type="submit"
                variant="gradient"
                sx={{ backgroundColor: '#C81414' }}>
                <MDTypography variant="body1" fontWeight="bold" color="white">
                  ログイン画面へ戻る
                </MDTypography>
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      </Card>
    );
  }

  if (loginMode === 0) {
    return (
      <BasicLayout image={newBgImage}>
        {!state.codeSent
          ? renderRequestCodeForm()
          : !state.passwordIsReset
          ? renderNewPasswordForm()
          : renderSuccessMessage()}
      </BasicLayout>
    );
  } else {
    return (
      <BasicLayout image={bgImage}>
        {!state.codeSent
          ? renderRequestCodeForm()
          : !state.passwordIsReset
          ? renderNewPasswordForm()
          : renderSuccessMessage()}
      </BasicLayout>
    );
  }
}
