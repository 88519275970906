import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import { IssueState, IssueStatus } from 'shared/constants/issues';

const propTypes = {
  modalClose: PropTypes.func,
  updateIssue: PropTypes.func,
  confirmModalUpdateData: PropTypes.object,
  confirmModalInfo: PropTypes.object,
};

const DashboardKanbanIssueDetailsConfirmPopUpWindow = ({
  modalClose,
  updateIssue,
  confirmModalUpdateData,
  confirmModalInfo,
}) => {
  const [isWorking] = useState(false);

  const handleYesButtonClick = () => {
    updateIssue(confirmModalUpdateData);
    modalClose();
  };

  const generateConfirmMessage = (confirmModalInfo) => {
    if (confirmModalInfo.issueState === IssueState.NORMAL) {
      if (confirmModalInfo.AssignType === 'assign') {
        return intl.get('kanban_confirm_popup_window_text_assign_doctor');
      } else if (confirmModalInfo.AssignType === 'unassign') {
        return intl.get('kanban_confirm_popup_window_text_unassign_doctor');
      }
      if (confirmModalInfo.destination === IssueStatus.CHECKREADY) {
        return intl.get('kanban_confirm_popup_window_text_check_ready');
      } else if (confirmModalInfo.destination === IssueStatus.DOCTORASSIGNED) {
        return intl.get('kanban_confirm_popup_window_text_doctor_assigned');
      } else if (confirmModalInfo.destination === IssueStatus.REPORTREADY) {
        return intl.get('kanban_confirm_popup_window_text_report_ready');
      } else if (confirmModalInfo.destination === IssueStatus.SENDBACK) {
        return intl.get('kanban_confirm_popup_window_text_send_back');
      } else if (confirmModalInfo.destination === IssueStatus.DONE) {
        return intl.get('kanban_confirm_popup_window_text_done');
      }
    } else if (confirmModalInfo.issueState === IssueState.WARNING) {
      return intl.get('kanban_confirm_popup_window_change_status_with_warning');
    }
    //TODO ! What is this??
    return 'hahahah';
  };
  const confirmMessage = generateConfirmMessage(confirmModalInfo);

  return (
    <Fragment>
      <Message>{confirmMessage}</Message>
      <Actions>
        <StyledButton
          variant="primary"
          isWorking={isWorking}
          onClick={handleYesButtonClick}>
          {intl.get('kanban_confirm_popup_window_button_yes')}
        </StyledButton>
        <StyledButton hollow onClick={modalClose}>
          {intl.get('kanban_confirm_popup_window_button_no')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};

DashboardKanbanIssueDetailsConfirmPopUpWindow.propTypes = propTypes;

export default DashboardKanbanIssueDetailsConfirmPopUpWindow;
