import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Star from './Star';
import FeeWaiver from './FeeWaiver';
import FeeAdjustment from './FeeAdjustment';

import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object,
  data: PropTypes.object,
  setData: PropTypes.func,
};

const DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelect = ({
  selectedRows,
  data,
  setData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Star selectedRows={selectedRows} data={data} setData={setData} />
      <FeeWaiver selectedRows={selectedRows} data={data} setData={setData} />
      <FeeAdjustment
        selectedRows={selectedRows}
        data={data}
        setData={setData}
      />
    </div>
  );
};

DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelect.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingForReceivedCasesDetailedTableCustomToolbarSelect
);
