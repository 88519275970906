import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Modal } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from 'mui-datatables';
import InvoiceInfo from '../../shared/InvoiceInfo';
import Invoice from '../../shared/Invoice';
import useStyles from './styles';
import { getPrice, calculateTotalPrice } from '../../shared/utils';
import { triggerLogForInvoicePDF } from 'pages/Dashboard/Billing/shared/api';
import CustomSearchRender from 'components/CustomSearchRender';

const propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  issuesData: PropTypes.array,
  pricesDict: PropTypes.object,
  srcOrgNamesDict: PropTypes.object,
  setSelectedSrcOrgId: PropTypes.func,
  setTableType: PropTypes.func,
  dstOrg: PropTypes.object,
  quotaSrcOrgsDict: PropTypes.object,
};

const DashboardBillingForReceivedCasesSummaryTable = ({
  selectedDate,
  issuesData,
  pricesDict,
  srcOrgNamesDict,
  setSelectedSrcOrgId,
  setTableType,
  dstOrg,
  quotaSrcOrgsDict,
}) => {
  const classes = useStyles();

  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const [modalInvoiceInfoOpen, setModalInvoiceInfoOpen] = useState(false);
  const [invoiceIssueDate, setInvoiceIssueDate] = useState(new Date());
  const [invoiceDueDate, setInvoiceDueDate] = useState(new Date());
  const [invoiceRemarksContent, setInvoiceRemarksContent] = useState('');
  const [invoiceIssuesData, setInvoiceIssuesData] = useState([]);
  const [invoiceSrcOrgName, setInvoiceSrcOrgName] = useState('');
  const [invoiceSrcOrgId, setInvoiceSrcOrgId] = useState(-1);
  const [invoiceVersion, setInvoiceVersion] = useState(0);

  const isInvoiceDate = (srcOrgId) => {
    const fullInvoiceDate = new Date(selectedDate);
    const invoiceDate = quotaSrcOrgsDict[srcOrgId].monthlyInvoiceDate;
    fullInvoiceDate.setDate(invoiceDate);

    const today = new Date();
    const dateDiff = (date1, date2) => {
      const oneDay = 1000 * 60 * 60 * 24;
      return Math.round((date1.getTime() - date2.getTime()) / oneDay);
    };

    const result = dateDiff(fullInvoiceDate, today);

    return result <= 0 ? true : false;
  };

  const getSummaryTableData = (_issuesData, _pricesDict, _srcOrgNameDict) => {
    const data = [];

    for (let srcOrgId in _srcOrgNameDict) {
      const rowData = [];

      // source organization name
      const srcOrgName = _srcOrgNameDict[srcOrgId];

      // calculate the total price
      const issues = _issuesData.filter(
        (item) => item.srcOrgId.toString() === srcOrgId
      );
      const totalPrice = calculateTotalPrice(issues, _pricesDict);

      // calculate the update date
      const updateDate = 'Update date';

      rowData.push(srcOrgName);
      rowData.push(totalPrice);
      rowData.push(srcOrgId);
      rowData.push(isInvoiceDate(srcOrgId) ? srcOrgId : null);
      rowData.push(updateDate);

      data.push(rowData);
    }

    return data;
  };

  const data = getSummaryTableData(issuesData, pricesDict, srcOrgNamesDict);

  const columns = [
    {
      name: 'from_organization_name',
      label: intl.get('billing_for_received_cases_label_column_received_from'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total_price',
      label: intl.get('billing_for_received_cases_label_column_total_price'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const totalPrice = value;
          return (
            <div>
              <p>￥{totalPrice}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'details',
      label: intl.get('billing_for_received_cases_label_column_details'),
      options: {
        customBodyRender: (value, talbeMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedSrcOrgId(value);
                  setTableType('detailed');
                }}>
                {intl.get('billing_for_received_cases_button_go_to_details')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'pdf_download',
      label: intl.get('billing_for_received_cases_label_column_pdf_download'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === null ? (
            <div>
              <Button variant="contained" color="primary" disabled="true">
                {intl.get('billing_for_received_cases_button_pdf_download')}
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const srcOrgId = parseInt(value);
                  const selectedIssuesData = issuesData.filter(
                    (issue) => issue.srcOrgId === srcOrgId
                  );

                  const _invoiceIssuesData = [];
                  selectedIssuesData.forEach((issue) => {
                    const p = getPrice(issue, pricesDict);
                    _invoiceIssuesData.push({
                      title: issue.title,
                      modality: issue.modality,
                      bodypart: issue.bodypart,
                      emergency: issue.emergency,
                      currentPrice: issue.waive
                        ? 0
                        : p * issue.discount + issue.difference,
                    });
                  });

                  triggerLogForInvoicePDF(srcOrgNamesDict[srcOrgId]);

                  setInvoiceIssuesData(_invoiceIssuesData);
                  // setModalInvoiceOpen(true);
                  setInvoiceSrcOrgName(srcOrgNamesDict[srcOrgId]);
                  setInvoiceSrcOrgId(srcOrgId);
                  setModalInvoiceInfoOpen(true);
                }}>
                {intl.get('billing_for_received_cases_button_pdf_download')}
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: 'update_date',
      label: intl.get('billing_for_received_cases_label_column_update_date'),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    filterType: 'textField',
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 25, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={intl.get(
            'billing_for_received_cases_text_received_cases'
          )}
        />
      );
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <Fragment>
      {/* TODO Add correct theming */}
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          title={intl.get('billing_for_received_cases_text_received_cases')}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {/* Irregular Modal */}
      <Modal
        open={modalInvoiceInfoOpen}
        onClose={() => {
          setModalInvoiceInfoOpen(false);
        }}
        className={classes.modalInvoiceInfo}>
        <DialogContent>
          <InvoiceInfo
            setModalInvoiceInfoOpen={setModalInvoiceInfoOpen}
            setModalInvoiceOpen={setModalInvoiceOpen}
            setInvoiceIssueDate={setInvoiceIssueDate}
            setInvoiceDueDate={setInvoiceDueDate}
            setInvoiceRemarksContent={setInvoiceRemarksContent}
            invoiceSrcOrgId={invoiceSrcOrgId}
            invoiceDstOrgId={dstOrg.id}
            quotaSrcOrgsDict={quotaSrcOrgsDict}
            setInvoiceVersion={setInvoiceVersion}
          />
        </DialogContent>
      </Modal>
      {/* Irregular Modal */}
      <Modal
        open={modalInvoiceOpen}
        onClose={() => {
          setModalInvoiceOpen(false);
        }}
        className={classes.modalInvoice}>
        <DialogContent>
          <div className={classes.modalInvoicePaper}>
            <Invoice
              invoiceSrcOrgName={invoiceSrcOrgName}
              invoiceSrcOrgId={invoiceSrcOrgId}
              invoiceIssuesData={invoiceIssuesData}
              invoiceIssueDate={invoiceIssueDate}
              invoiceDueDate={invoiceDueDate}
              invoiceRemarksContent={invoiceRemarksContent}
              invoiceDstOrg={dstOrg}
              invoiceVersion={invoiceVersion}
            />
          </div>
        </DialogContent>
      </Modal>
    </Fragment>
  );
};

DashboardBillingForReceivedCasesSummaryTable.propTypes = propTypes;

export default DashboardBillingForReceivedCasesSummaryTable;
