import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Preference from './Preference';
import ClientManagement from './ClientManagement';
import UserManagement from './UserManagement';
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { PageLoader, PageError } from 'components';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';

const DashboardOrganizationSettings = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [value, setValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const tabStyle = { color: darkMode ? 'white' : 'grey' };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [{ data, error }, fetchOrganizationInfo] = useApi.get(
    '/api/organizations/organization_info',
    {
      params: { organizationId: localData.get('currentOrganizationId') },
    }
  );

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const organizationInfo = {
    ...data,
  };

  if (localData.get('currentOrganization') !== organizationInfo.name) {
    localData.set('currentOrganization', organizationInfo.name);
  }

  return (
    <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        <MDBox pl={1} mb={1} display="flex">
          <MDTypography variant="h3">
            {intl.get('organization_settings_text_title')}
          </MDTypography>
        </MDBox>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          alignContent="flex-start">
          <Grid
            container
            item
            xs={8}
            direction="column"
            justifyContent="center">
            <Grid container item xs={12} justifyContent="center">
              <MDBox>
                <Tabs
                  style={tabStyle}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab
                    label={intl.get(
                      'organization_settings_tab_label_preference'
                    )}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={intl.get(
                      'organization_settings_tab_label_client_management'
                    )}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={intl.get(
                      'organization_settings_tab_label_user_management'
                    )}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </MDBox>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              {value === 0 && (
                <MDBox sx={{ p: 3 }}>
                  <Preference organizationInfo={organizationInfo} />
                </MDBox>
              )}
              {value === 1 && (
                <MDBox sx={{ p: 3 }}>
                  <ClientManagement organizationInfo={organizationInfo} />
                </MDBox>
              )}
              {value === 2 && (
                <MDBox sx={{ p: 3 }}>
                  <UserManagement
                    organizationInfo={organizationInfo}
                    fetchOrganizationInfo={fetchOrganizationInfo}
                  />
                </MDBox>
              )}
              {value === 3 && <MDBox sx={{ p: 3 }}></MDBox>}
              {value === 3 && <MDBox sx={{ p: 3 }}></MDBox>}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default withRouter(DashboardOrganizationSettings);
