import intl from 'react-intl-universal';
import { Button, Typography } from '@material-ui/core';
import State from 'components/DataTable/State';
import Deadline from 'components/DataTable/Deadline';
import Emergency from 'components/DataTable/Emergency';
import { formatTime } from 'shared/utils/formatTime';
import {
  IssueStatus,
  IssueStatusToOrder,
  getIssueState,
  getIssuePriority,
  getIssueStatus,
} from 'shared/constants/issues';
import { Status } from './styles';

export const getReceivedTableColumns = (handleOpenIssueDetails) => [
  {
    name: 'case_title',
    label: intl.get('case_list_label_column_case_title'),
    options: {
      filter: true,
      filterType: 'textField',
      sort: false,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          const words1 = obj1.data.split('-');
          const words2 = obj2.data.split('-');
          if (words1[0] > words2[0]) {
            return order === 'desc' ? -1 : 1;
          } else if (words1[0] < words2[0]) {
            return order === 'desc' ? 1 : -1;
          } else {
            if (words1[1] > words2[1]) {
              return order === 'desc' ? -1 : 1;
            } else if (words1[1] < words2[1]) {
              return order === 'desc' ? 1 : -1;
            } else {
              return (
                (parseInt(words1[2], 10) - parseInt(words2[2], 10)) *
                (order === 'desc' ? -1 : 1)
              );
            }
          }
        };
      },
    },
  },
  {
    name: 'status',
    label: intl.get('case_list_label_column_status'),
    options: {
      filter: true,
      filterType: 'multiselect',
      filterOptions: {
        names: [
          IssueStatus.NEWTASK,
          IssueStatus.CHECKREADY,
          IssueStatus.DOCTORASSIGNED,
          IssueStatus.REPORTREADY,
          IssueStatus.SENDBACK,
          IssueStatus.DONE,
        ],
        renderValue: (value) => {
          const status = value;
          return getIssueStatus(status);
        },
      },
      customFilterListOptions: {
        render: (value) => {
          return (
            intl.get('case_list_label_column_status') +
            ': ' +
            getIssueStatus(value)
          );
        },
      },
      sort: true,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          const order1 = IssueStatusToOrder[obj1.data];
          const order2 = IssueStatusToOrder[obj2.data];
          if (order1 > order2) {
            return order === 'desc' ? -1 : 1;
          } else if (order1 < order2) {
            return order === 'desc' ? 1 : -1;
          } else {
            return 1;
          }
        };
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const status = value;
        return <Status color={status}>{getIssueStatus(value)}</Status>;
      },
    },
  },
  {
    name: 'state',
    label: intl.get('case_list_label_column_state'),
    options: {
      filter: true,
      filterType: 'multiselect',
      filterOptions: {
        renderValue: (value) => {
          const state = value;
          return getIssueState(state);
        },
      },
      customFilterListOptions: {
        render: (value) => {
          const state = value;
          return (
            intl.get('case_list_label_column_state') +
            ': ' +
            getIssueState(state)
          );
        },
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const state = value;
        return (
          <div>
            <State state={state} />
          </div>
        );
      },
    },
  },
  {
    name: 'emergency',
    label: intl.get('case_list_label_column_emergency'),
    options: {
      filter: true,
      filterType: 'multiselect',
      filterOptions: {
        renderValue: (value) => {
          const priority = value;
          return (
            <div>
              <Emergency priority={priority} />
            </div>
          );
        },
      },
      customFilterListOptions: {
        render: (value) => {
          const priority = value;
          return (
            intl.get('case_list_label_column_emergency') +
            ': ' +
            getIssuePriority(priority)
          );
        },
      },
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        const priority = value;
        return (
          <div>
            <Emergency priority={priority} />
          </div>
        );
      },
    },
  },
  {
    name: 'deadline',
    label: intl.get('case_list_label_column_deadline'),
    options: {
      filter: false,
      sort: true,
      sortCompare: (order) => {
        return (obj1, obj2) => {
          const deadline1 = new Date(obj1.data);
          const deadline2 = new Date(obj2.data);
          if (deadline1 > deadline2) {
            return order === 'desc' ? -1 : 1;
          } else if (deadline1 < deadline2) {
            return order === 'desc' ? 1 : -1;
          } else {
            return 1;
          }
        };
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        const deadline = value;
        return (
          <div>
            <Deadline deadline={deadline} />
          </div>
        );
      },
    },
  },
  {
    name: 'order_time',
    label: intl.get('case_list_label_column_order_time'),
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return formatTime(value);
      },
    },
  },
  {
    name: 'from',
    label: intl.get('case_list_label_column_from'),
    options: {
      filter: true,
      filterType: 'multiselect',
      customFilterListOptions: {
        render: (value) => {
          const hospital = value;
          return intl.get('case_list_label_column_from') + ': ' + hospital;
        },
      },
      sort: true,
    },
  },
  {
    name: 'doctor',
    label: intl.get('case_list_label_column_doctor_in_charge'),
    options: {
      filter: true,
      filterType: 'multiselect',
      customFilterListOptions: {
        render: (value) => {
          const doctor = value;
          return (
            intl.get('case_list_label_column_doctor_in_charge') + ': ' + doctor
          );
        },
      },
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const doctorList = value;
        var doctorListString = doctorList[0];
        for (var index = 1; index < doctorList.length; index++) {
          doctorListString += '; ' + doctorList[index];
        }
        return (
          <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
            {doctorListString}
          </Typography>
        );
      },
    },
  },
  {
    name: 'view',
    label: intl.get('case_list_label_column_details'),
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        const issueId = value;
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpenIssueDetails(issueId);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          </div>
        );
      },
    },
  },
];
