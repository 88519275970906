import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser'; // processNodes // convertNodeToElement,
import api from 'shared/utils/api';
import { formatDateTimeConversational } from 'shared/utils/dateTime';
import { ConfirmModal } from 'components';
import localData from 'shared/utils/localData';

import {
  Comment,
  UserAvatar,
  Content,
  Username,
  CreatedAt,
  HTMLParsedBody,
  DeleteLink,
} from './Styles';
import './styles.css';
import { getUserFullName } from 'shared/utils/name';

// TODO Update PropTypes
const propTypes = {
  comment: PropTypes.object.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  handleBlockQuoteTextClick: PropTypes.func,
};

const ProjectBoardIssueDetailsComment = ({
  comment,
  fetchIssue,
  handleBlockQuoteTextClick,
}) => {
  const username = localData.get('username');
  const currentOrganizationId = localData.get('currentOrganizationId');
  const commentUserFullName = getUserFullName(
    comment.user.firstName,
    comment.user.lastName
  );

  const handleCommentDelete = async () => {
    try {
      await api.delete(`/api/comments/${comment.id}`, {
        username: username,
        organizationId: currentOrganizationId,
        params: {
          organizationId: currentOrganizationId,
        },
      });
      await fetchIssue();
    } catch (error) {
      console.error(error);
    }
  };

  const onBlockquoteTextClick = (e) => {
    handleBlockQuoteTextClick(e.target.innerText);
  };

  const transform = (node, index) => {
    // return null to block certain elements.
    // e.g. don't allow <span> elements:
    if (node.type === 'tag' && node.name === 'span') {
      return null;
    }
    if (node.type === 'tag' && node.name === 'blockquote') {
      return (
        <blockquote className="blockquote" key={index}>
          <div className="blockquoteText" onClick={onBlockquoteTextClick}>
            {node.children[0].data}
          </div>
        </blockquote>
      );
    }
  };

  const ReactHtmlParserOptions = {
    decodeEntities: true,
    transform,
  };
  return (
    <Comment data-testid="issue-comment">
      {/* <UserAvatar name={comment.user.username} avatarUrl={comment.user.avatarUrl} /> */}
      <UserAvatar name={commentUserFullName} />
      <Content>
        <Username>
          {commentUserFullName +
            ' ' +
            (comment.domainType === 'LOCAL'
              ? intl.get('issue_details_comment_label_published_locally')
              : intl.get('issue_details_comment_label_published_globally'))}
        </Username>
        <CreatedAt>{formatDateTimeConversational(comment.createdAt)}</CreatedAt>

        <Fragment>
          <div>
            <HTMLParsedBody>
              {ReactHtmlParser(comment.body, ReactHtmlParserOptions)}
            </HTMLParsedBody>
          </div>
          {username === comment.user.username && (
            <ConfirmModal
              title={intl.get(
                'issue_details_confirm_modal_text_delete_comment_title'
              )}
              message={intl.get(
                'issue_details_confirm_modal_text_delete_comment_message'
              )}
              confirmText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_confirm_text'
              )}
              cancelText={intl.get(
                'issue_details_confirm_modal_button_delete_comment_cancel_text'
              )}
              onConfirm={handleCommentDelete}
              renderLink={(modal) => (
                <DeleteLink onClick={modal.open}>Delete</DeleteLink>
              )}
            />
          )}
        </Fragment>
      </Content>
    </Comment>
  );
};

ProjectBoardIssueDetailsComment.propTypes = propTypes;

export default ProjectBoardIssueDetailsComment;
