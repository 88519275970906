import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import { useMaterialUIController } from 'context';
import pxToRem from 'assets/theme/functions/pxToRem';
import localData from 'shared/utils/localData';
import api from 'shared/utils/api';
import ServiceTermsJP from 'assets/documents/service-terms/service-terms.txt';

const DashboardServiceTermsPopUpWindow = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [userAgreedServiceTerms, setUserAgreedServiceTerms] = useState(false);
  const [serviceTermsContext, setServiceTermsContext] = useState('');

  const handleAgreeServiceTerms = () => {
    const variables = {
      data: {},
    };
    api.put('/api/user/service-terms', variables).then(
      (data) => {
        window.location.reload();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const preferredLanguage = localData.get('userPreferredLanguage');

  useEffect(() => {
    if (preferredLanguage === 'ja-JP') {
      fetch(ServiceTermsJP)
        .then((r) => r.text())
        .then((text) => {
          setServiceTermsContext(text);
        });
    }
  }, [preferredLanguage]);

  return (
    <Card sx={{ margin: 4, marginTop: 3, width: 800, maxHeight: '90vh' }}>
      <MDBox py={3} px={3} mt={3} mx={3} sx={{ overflow: 'auto' }}>
        <MDTypography mb={1}>
          {intl.get('dashboard_service_terms_modal_text_title')}
        </MDTypography>
        <MDTypography>
          {intl.get('dashboard_service_terms_modal_text_thanks')}
        </MDTypography>
        <br />
        <div style={{ overflow: 'auto' }}>
          <TextEditedContent content={serviceTermsContext} />
        </div>

        <Grid
          spacing={3}
          container
          justify="flex-end"
          alignItems="flex-end"
          style={{ marginTop: '20px' }}>
          <Grid xs={4} item>
            <FormControlLabel
              style={{ color: darkMode ? 'white' : 'black' }}
              control={
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                  }
                  onChange={() => {
                    setUserAgreedServiceTerms(!userAgreedServiceTerms);
                  }}
                />
              }
              label={intl.get('dashboard_service_terms_modal_label_agree')}
            />
          </Grid>
          <Grid xs={12} item>
            <MDButton
              variant="gradient"
              color="info"
              disabled={!userAgreedServiceTerms}
              style={{
                color: 'white',
                fontSize: pxToRem(18),
                marginTop: '5px',
                marginBottom: '15px',
                width: '50%',
                marginLeft: '25%',
              }}
              onClick={handleAgreeServiceTerms}>
              {intl.get('dashboard_service_terms_modal_button_proceed')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default DashboardServiceTermsPopUpWindow;
