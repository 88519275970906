import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import ServiceTermsJaJP from './ServiceTermsJaJP';
import localData from 'shared/utils/localData';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';

const DashboardServiceTerms = () => {
  const preferredLanguage = localData.get('userPreferredLanguage');

  return (
    <Fragment>
      <Card
        sx={{
          margin: 4,
          marginTop: 3,
          overflow: 'visible',
          height: '93%',
          alignItems: 'center',
        }}>
        <MDBox
          py={3}
          px={3}
          mt={3}
          mx={3}
          sx={{ height: '100%', width: '100%' }}>
          {preferredLanguage === 'ja-JP' && <ServiceTermsJaJP />}
          {preferredLanguage === 'en-US' && <ServiceTermsJaJP />}
          {preferredLanguage === 'zh-CN' && <ServiceTermsJaJP />}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardServiceTerms);
