import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';

import { PageLoader, PageError, Modal } from 'components';

// components and parts
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';

import Delete from '../Delete';
import New from './New';

import CustomSearchRender from 'components/CustomSearchRender/admin';

const propTypes = {};

const IWGDashboardEmployments = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [employmentIdsSelected, setEmploymentIdsSelected] = useState({
    orgId: -1,
    userId: -1,
    quotaUserId: -1,
  });
  const [employmentDataSelected, setEmploymentDataSelected] = useState({});
  const [employmentNewModalOpen, setEmploymentNewModalOpen] = useState(false);
  const [employmentDeleteModalOpen, setEmploymentDeleteModalOpen] =
    useState(false);

  const [{ data, error }, fetchEmployments] = useApi.get(
    '/api/admin/employments',
    {}
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const handleOpenEmploymentDetails = () => {};

  const handleOpenDeleteEmployment = (employment) => {
    setEmploymentIdsSelected({
      orgId: employment.orgId,
      userId: employment.userId,
      quotaUserId: employment.quotaUserId,
    });
    setEmploymentDataSelected((prevState) => ({
      ...employment,
    }));
    setEmploymentDeleteModalOpen(true);
  };

  const handleDeleteEmployment = async (employmentIds) => {
    await api.delete('/api/admin/employments', {
      data: {
        ids: [employmentIds],
      },
    });

    await fetchEmployments();
  };

  data.sort((employment1, employment2) => {
    if (employment1.orgId > employment2.orgId) return 1;
    if (employment1.orgId < employment2.orgId) return -1;
    if (employment1.userId > employment2.userId) return 1;
    if (employment1.userId < employment2.userId) return -1;
    return 0;
  });

  const tableData = data.map((employment) => {
    return [
      employment.orgName,
      employment.userFirstName + ' ' + employment.userLastName,
      employment.userType,
      employment.quota,
      employment.used,
      employment,
      employment,
    ];
  });

  const columns = [
    {
      name: 'orgName',
      label: 'Organization Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userName',
      label: 'User Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userType',
      label: 'User Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'quota',
      label: 'Quota',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'used',
      label: 'Used',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const employment = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenEmploymentDetails(employment);
                }}>
                Details
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const employment = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteEmployment(employment);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    responsive: 'stacked',
    rowsPerPageOptions: [20, 50, 100],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            <Grid container item xs={4} columnSpacing={3}>
              <Grid item>
                <h2>Employments</h2>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setEmploymentNewModalOpen(true);
                  }}>
                  New
                </MDButton>
              </Grid>
            </Grid>
          }
        />
      );
    },
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h2>Employments</h2>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEmploymentNewModalOpen(true);
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>

        {employmentNewModalOpen && (
          <Modal
            isOpen
            testId="modal:admin-employment-new"
            width={1040}
            withCloseIcon={true}
            onClose={() => setEmploymentNewModalOpen(false)}
            renderContent={(modal) => (
              <New close={modal.close} fetchEmployments={fetchEmployments} />
            )}
          />
        )}
        {employmentDeleteModalOpen && (
          <Modal
            isOpen
            testId="modal:admin-org-delete"
            width={1040}
            withCloseIcon={true}
            onClose={() => setEmploymentDeleteModalOpen(false)}
            renderContent={(modal) => (
              <Delete
                dataType="Employment"
                dataId={employmentIdsSelected}
                data={employmentDataSelected}
                deleteData={handleDeleteEmployment}
                close={setEmploymentDeleteModalOpen}
              />
            )}
          />
        )}
      </Card>
    </Fragment>
  );
};

IWGDashboardEmployments.propTypes = propTypes;

export default withRouter(IWGDashboardEmployments);
